<template>
    <div>
        <md-drawer  class="listado-reservas-filters" :md-active.sync="showFilters" md-right>
            <md-toolbar class="md-transparent" md-elevation="0">
                <span class="md-title" style="padding-top: 10px;"><md-icon>filter_alt</md-icon>{{lang.filtrar}}</span>
            </md-toolbar>
            <div class="drawer-content">
                <md-field >
                    <md-icon>search</md-icon>
                    <label>{{lang.titular}}</label>
                    <md-input v-model="query.nombre"></md-input>
                </md-field>
                <md-field>
                    <md-icon>bed</md-icon>
                    <label>{{lang.habitacion}}</label>
                    <md-select v-model="query.id_cuarto">
                        <md-option value="">{{lang.seleccione}}</md-option>
                        <md-option v-for="(cuarto, index) in habitaciones" :value="cuarto.id_cuarto" :key=" cuarto.id_cuarto">{{cuarto.nombre}}</md-option>
                    </md-select>
                </md-field>
                <md-field>
                    <md-icon>travel_explore</md-icon>
                    <label>{{lang.agencia_canal}}</label>
                    <md-select v-model="query.id_agencia">
                        <md-option value="">{{lang.ninguna}}</md-option>
                        <md-optgroup  v-for="(canales, name) in agencias" :label="lang[name]" :key="name" v-if="canales.length > 0">
                            <md-option :value="canal.id_agencia" v-for="(canal, index) in canales" :key="index">{{canal.nombre}}</md-option>
                        </md-optgroup>
                    </md-select>
                </md-field>

                <md-datepicker v-model="query.creaciondesde"  md-model-type = "string">
                    <label>{{lang.fecha_creacion_desde}}</label>
                </md-datepicker>
                <md-datepicker v-model="query.creacionhasta"  md-model-type = "string">
                    <label>{{lang.fecha_creacion_hasta}}</label>
                </md-datepicker>
                <md-datepicker v-model="query.desde"  md-model-type = "string">
                    <label>{{lang.fecha_entrada_desde}}</label>
                </md-datepicker>
                <md-datepicker v-model="query.hasta" md-model-type = "string" >
                    <label>{{lang.fecha_entrada_hasta}}</label>
                </md-datepicker>
                <md-datepicker v-model="query.salidadesde"  md-model-type = "string">
                    <label>{{lang.fecha_salida_desde}}</label>
                </md-datepicker>
                <md-datepicker v-model="query.salidahasta"  md-model-type = "string">
                    <label>{{lang.fecha_salida_hasta}}</label>
                </md-datepicker>
            </div>
            <div style="padding: 1rem">
                <md-button class="md-raised md-primary" style="width: 100%" @click="filtrar()">{{lang.enviar}}</md-button>
            </div>
        </md-drawer>

        <div class="md-content ">
            <div class="toolbar" style="" >
                <i class="main-table-pagination-label" style="padding: 10px ">
                    {{lang.del}} {{pagination.desde}} {{lang.al}} {{pagination.hasta}}  {{lang.de}} {{pagination.total_registros}}
                    <span v-if="filtered"> ({{lang.filtrado}}) </span>
                </i>
                <div style="display: flex; justify-content: flex-end">
                    <div class=""  style="width: 40px; text-align: center">
                        <a @click="refresh()">
                            <md-icon>refresh</md-icon>
                        </a>
                    </div>
                    <div class="" style="width: 40px; text-align: center" >
                        <router-link  :to="{name:'listado.filters'}" style="padding: 10px 10px 10px 2px" >
                            <md-icon>filter_alt</md-icon>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="main-table">
                <template v-if="data.length > 0">
                    <md-card class="md-card-timeline" :md-with-hover="true" v-for="item in data" :key="item.id" >
                        <div class="md-subhead" style="">
                            <md-avatar class="md-avatar-icon md-small" style="margin-right: 5px;">{{avatar(nombre(item))}}</md-avatar> {{nombre(item).toUpperCase()}}
                        </div>
                        <div class="md-subhead" style="padding-bottom: 5px">
                            <md-icon class = "md-small">king_bed</md-icon> <span><strong>{{item.nombre_categoria}} </strong> | {{item.nombre_cuarto}} </span>
                        </div>
                        <md-card-content>
                            <div class="details">
                                <div class="md-layout md-gutter">
                                    <div class="md-layout-item">
                                        <md-icon class = "md-small" >flag</md-icon>
                                        <div class = "md-list-item-text">
                                            <span>{{item.id_reserva}}</span>
                                            <span class="label">{{lang.reserva}}</span>
                                        </div>
                                    </div>
                                    <div class="md-layout-item">
                                        <md-icon class = "md-small" >schedule
                                        </md-icon>
                                        <div class = "md-list-item-text">
                                            <span>{{item.fecha_creacion}}</span>
                                            <span class="label">{{lang.fecha_creacion}}</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="md-layout md-gutter ">
                                    <div class="md-layout-item">
                                        <md-icon class = "md-small ">flight_land</md-icon>
                                        <div class = "md-list-item-text">
                                            <span v-html="wrap(item.fecha_ingreso)"></span>
                                            <span class="label">Check in</span>
                                        </div>
                                    </div>
                                    <div class="md-layout-item">
                                        <md-icon class = "md-small">flight_takeoff</md-icon>
                                        <div class = "md-list-item-text">
                                            <span v-html="wrap(item.fecha_salida)"></span>
                                            <span class="label">Check out</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="md-layout md-gutter" v-if="false">
                                    <div class="md-layout-item">
                                        <md-icon class = "md-small">contactless</md-icon>
                                        <div class = "md-list-item-text">
                                            <span>{{item.nombre_agencia}}</span>
                                            <span class="label">{{lang.canal}}</span>
                                        </div></div>
                                    <div class="md-layout-item">
                                        <md-icon class = "md-small">info</md-icon>
                                        <div class = "md-list-item-text">
                                            <span>{{item.estatus}}</span>
                                            <span class="label">{{lang.estatus}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {{item.text}}
                        </md-card-content>
                        <div class="md-foot">
                            <router-link :to="`/Reserva/${item.id_reserva}/detalle`">
                                <md-chip>
                                    <span style="display: flex; align-items: center">
                                        <span style="margin-right: 10px">{{lang.ver}}</span>
                                        <md-icon>arrow_forward</md-icon>
                                    </span>
                                </md-chip>
                            </router-link>
                        </div>
                    </md-card>
                </template>
                <md-empty-state v-else md-icon="view_list" :md-label="$store.state.lang.sin_registros" />
            </div>
            <div class="main-table-pagination">
                <i class="main-table-pagination-label">
                  Pag {{pagination.pagina}}
                </i>
                <div class="main-table-pagination-btns">
                    <md-button @click="first_page()">
                        <md-icon>first_page</md-icon>
                    </md-button>
                    <md-button @click="prev_page()">
                        <md-icon>chevron_left</md-icon>
                    </md-button>
                    <md-button @click="next_page()">
                        <md-icon>chevron_right</md-icon>
                    </md-button>
                    <md-button @click="last_page()">
                        <md-icon>last_page</md-icon>
                    </md-button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {empty, request, Utility, avatar} from "@/helpers/"
    import jsonToQuery from 'json-to-http-query-string'

    export default {
        name: "Resumen",
        data(){
            return {
                selected :{
                    ingreso :null
                },
                query:{
                    desde: '',
                    hasta:'',
                    creaciondesde : '',
                    creacionhasta : '',
                    salidadesde :'',
                    salidahasta : '',
                    id_cuarto :'',
                    nombre :'',
                    id_agencia :'',
                    id_agenciaonline :'',
                    page :0
                },
                pagination : {
                    pagina : 1,
                    total_pag : 0,
                    item_pag : 20,
                    desde : 1,
                    hasta : 20,
                    total_registros : 0
                },

                data :[]
            }
        },
        computed:{
            showFilters:{

                set(){ this.$router.push({name:'listado'})},
                get(){ return (this.$route.name == 'listado.filters')}
            },
            lang(){return this.$store.state.lang},
            agencias(){return this.$store.state.calendarData.canales},
            habitaciones(){
                let data = [];
                let rooms = this.$store.getters.habitaciones;
                if(rooms && rooms.length > 0){
                    rooms.forEach((tipo, i) => {
                        tipo.categorias.forEach((category, j) => {
                            data = data.concat(category.habitaciones)
                        })
                    });
                }
                return data;
            },
            filtered(){
                return ( this.query.desde !== '' ||
                         this.query.hasta !=='' ||
                         this.query.creaciondesde  !== '' ||
                         this.query.creacionhasta  !== '' ||
                         this.query.salidadesde  !=='' ||
                         this.query.salidahasta  !== '' ||
                         this.query.id_cuarto  !=='' ||
                         this.query.nombre  !=='' ||
                         this.query.id_agencia  !=='' ||
                         this.query.id_agenciaonline  !=='')
            }
        },

        methods :{
            refresh(){
                this.query = {
                    desde: '',
                    hasta:'',
                    creaciondesde : '',
                    creacionhasta : '',
                    salidadesde :'',
                    salidahasta : '',
                    id_cuarto :'',
                    nombre :'',
                    id_agencia :'',
                    id_agenciaonline :'',
                    page :0
                };
                this.first_page();
            },
            filtrar(){
               this.showFilters = false;
               this.first_page();
            },
            first_page(){
                this.query.page = 0;
                this.loadData();
            },
            prev_page(){
                if(this.query.page > 0) {
                    this.query.page = this.query.page - 20;
                    this.loadData();
                }
            },
            next_page(){
                if(this.query.page + 20 < this.pagination.total_registros) {
                    this.query.page = this.query.page + 20;
                    this.loadData();
                }
            },
            last_page(){
               if(this.pagination.total_registros > 20) {
                   let last_page = Math.floor(this.pagination.total_registros / 20 );
                   this.query.page = last_page * 20;
                   this.loadData();
               }
            },
         async loadData(){
              this.$store.state.loading = true;
              let r = await  request(base_url+'/reservas?view=json&jwt='+this.$store.state.jwt+'&'+jsonToQuery(this.query));
              r = r.r.data;
              if(r.status == 'ok'){
                  this.data = r.data;
                  this.pagination = r.pagination;
              }

             this.$store.state.loading = false;
          },
            wrap: function(date){
                if(!empty(date)){
                    date = date.split(' ');
                    date = date[0]
                }
                return date
            },
            nombre(item){
                if(item) {
                    let nombre = (item.nombre) ?? '';
                    let primer_apellido = (item.primer_apellido) ?? '';
                    return nombre + ' ' + primer_apellido;
                }
                return  'NN';
            },
            avatar(name){
                return avatar(name)
            }
        },
        async beforeMount(){
            if(!this.$store.getters.habitaciones){
                App.lobbyCalendar.render();
            }
        },
        mounted() {
            this.$store.state.loading = false;
            this.$store.commit('setTitle', this.lang.reservas_registradas);
            this.$nextTick(function(){
                this.loadData();
            })
        }
    }
</script>

<style lang="scss">
    .listado-reservas-filters{
        width: auto !important;
        .drawer-content{
            padding: 0 1rem 1rem;
            .md-field {
                width: 100%;
                min-height: 16px;
                margin: 4px 0 10px;
                padding-top: 16px;
                display: flex;
                position: relative;
                font-family: inherit;

                input.md-input.md-input.md-select-value {
                    padding-left: 10px;
                }

            }
        }
    }
</style>
<style lang="scss" scoped>
    .md-card-timeline{
        margin-bottom: 10px;
    }
    .md-subhead{
        padding: 7px 7px;
        border-bottom: 1px solid #b5b5b5;
    }
    .md-foot{
        padding: 7px 7px 10px;
        text-align: right;
    }

    .details{
        div.md-layout-item{
            padding-top: 10px;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-items: flex-start;
            i {
                margin-right: 4px;
            }
            .label{
                font-size: 12px;
                color: rgba(0,0,0,0.54);
            }
        }
    }


    .md-content {
        position: relative;
        height: calc(100vh - 56px);
        overflow-x: hidden;
        overflow-y: hidden;
        width: 100%;
        .toolbar {
            position: absolute;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items:  center;
            align-content: center;
            height: 50px;
            background-color: #fff;
            width: 100%;
            z-index: 2;
        }


        .main-table{

            padding: 60px 1rem;
            height: calc(100vh - 56px);
            overflow-x: hidden;
            overflow-y: auto;
        }

        .main-table-pagination{
            position: absolute;
            width: 100%;
            top: 100%;
            margin-top: -49px;
            background-color: #fff;
            height: 50px;
            z-index: 2;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
            .main-table-pagination-label{
                padding: 5px;
            }
            .main-table-pagination-btns{
                padding: 5px;
                .md-button {
                    padding: 0 7px;
                    min-width: auto;
                }
            }
        }

        .sub-head {
            font-size: 14px;
            color: rgba(0, 0, 0, .6);
        }

        .md-select-value {
            padding-left: 13px;
        }


        .alert {
            text-align: center;
            margin-bottom: 10px;
            background-color: #ff5252;
            color: white;
            font-size: 14px;
            padding: 7px;
        }
    }
</style>
